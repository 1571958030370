.glassmorphism-effect {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 08px32px0rgba (31, 38, 135, 0.37);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  // border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.28);
}

.progress-ring {
}

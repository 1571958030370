.navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 12px;
  background-color: #fff;
  position: absolute;
  top: 0;
  -webkit-transition: padding-top 0.2s ease;
  transition: padding-top 0.2s ease;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 80px;
  .search-bar {
    flex-grow: 1;
    margin: 0 48px;
    svg {
      z-index: 1;
      margin: auto;
      path {
        fill: #25262640;
      }
    }
    input {
      width: 100%;
      height: 32px;
      padding-left: 32px;
      border: none;
      font-size: 14px;
      font-family: "Nunito Sans";
      background-color: #edf1f3;
      border-radius: 6px;
      height: 40px;
      padding-left: 48px;
      margin-left: -40px;
      padding-right: 10px;
      &:focus,
      &:active {
        outline-color: $beek_green;
      }
    }
  }
}

@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700,900);
@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@import "./variables.scss";
@import "./utils.scss";
@import "./navbar.scss";
@import "./player.scss";

body {
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  overflow: hidden;
}
